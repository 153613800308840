import React, { useState, useEffect } from 'react';
import TabContent from './TabContent';
import axios from 'axios';
import Home2 from '../pages/Tab2';
import Home3 from '../pages/Tab3';
import Home4 from '../pages/Tab4';
import Home5 from '../pages/Tab5';
//import Home6 from '../pages/Tab6';
import Home7 from '../pages/Tab7';
import { Routes, Route, useParams } from 'react-router-dom';
import '../css/Tabs.css'; // Importa los estilos CSS
import logo from '../imgs/logo.png';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [clienteNombre, setClienteNombre] = useState('');

  // Obtén el valor de cli de la URL
  const { cli } = useParams();

  useEffect(() => {
    // Realiza una solicitud al servidor para obtener el nombre del cliente
    axios.get(`https://apigabtecbi.automarco.cl:3003/api/datosCliente?ven=${cli}`)
      .then((response) => {
        setClienteNombre(response.data.clienteNombre);
      })
      .catch((error) => {
        console.error('Error al obtener el nombre del cliente:', error);
      });
  }, [cli]);  // Asegúrate de ejecutar la solicitud cada vez que cambia "cli"

  const handleTabClick = (index, event) => {
    event.preventDefault(); // Previene la recarga de la página
    setActiveTab(index);    // Actualiza la pestaña activa
  };

  const tabsData = [
    { label: 'Histórico', content: <Home5 cli={cli} /> },
    { label: 'Frecuentes', content: <TabContent cli={cli} /> },
    { label: 'No compra +', content: <Home2 cli={cli} /> },
    { label: 'Potenciales', content: <Home3 cli={cli} /> },
    { label: 'Nuevos', content: <Home4 cli={cli} /> },
    //{ label: 'Vta. Mensual', content: <Home6 cli={cli} /> },
    { label: 'Sin Atencion', content: <Home7 cli={cli} /> },
    // Agrega más pestañas según sea necesario
  ];
  
  return (
    <div>
      <div>
        <h2 className='titulo_cliente'>Cliente: {clienteNombre}</h2>
      </div>
      <ul id="nav">
        {tabsData.map((tab, index) => (
          <li key={index}>
            <a
              href="#" // Puedes dejar el href, pero lo prevenimos en el onClick
              className={index === activeTab ? 'active' : ''}
              onClick={(event) => handleTabClick(index, event)} // Prevenimos el comportamiento predeterminado
            >
              {tab.label}
            </a>
          </li>
        ))}
        <li style={{ float: 'right' }}><img src={logo} height="43" alt='Holding Automarco'></img></li>
      </ul>
      <div className="tab-content">
        {/* Solo necesitas una ruta principal para los tabs */}
        <Routes>
          <Route path="/" element={tabsData[activeTab].content} />
        </Routes>
      </div>
    </div>
  );
};

export default Tabs;
